export default [
  {
    title: 'Dashboard',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'Appointments Dashboard',
        route: 'appointments-dashboard',
        icon: 'CircleIcon',
      },
      {
        title: 'Appointments Details',
        route: 'appointments-details',
        icon: 'CircleIcon',
      },
      {
        title: 'Representatives Dashboard',
        route: 'representatives-dashboard',
        icon: 'CircleIcon',
      },
    ],
  },
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Actvities',
    route: 'activities',
    icon: 'PhoneCallIcon',
  },
  {
    title: 'Reports',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Interactive Reporter',
        route: 'interactive-reporter',
        icon: 'CircleIcon',
      },
      {
        title: 'Daily Appointment',
        route: 'daily-appointment',
        icon: 'CircleIcon',
      },
      {
        title: 'Daily Visit',
        route: 'daily-visit',
        icon: 'CircleIcon',
      },
      {
        title: 'Occurred Visits',
        route: 'occurred-visits',
        icon: 'CircleIcon',
      },
      {
        title: 'Canceled Visits',
        route: 'canceled-visits',
        icon: 'CircleIcon',
      },
      {
        title: 'General',
        route: 'general',
        icon: 'CircleIcon',
      },
    ],
  },
  {
    title: 'Profile',
    route: 'profile',
    icon: 'UserPlusIcon',
  },
  {
    title: 'Leads',
    route: 'leads',
    icon: 'UserIcon',
  },
  {
    title: 'Appointments',
    route: 'appointments',
    icon: 'CalendarIcon',
  },
  {
    title: 'CRM',
    route: 'crm',
    icon: 'UsersIcon',
  },
  {
    title: 'Lists',
    icon: 'ServerIcon',
    children: [
      {
        title: 'Roster Importer',
        route: 'roster-importer',
        icon: 'FilePlusIcon',
      },
    ],
  },
  {
    title: 'Settings',
    route: 'settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Catalogs',
        route: 'catalogs',
        icon: 'BoxIcon',
      },
      {
        title: 'Users',
        route: 'users',
        icon: 'BoxIcon',
      },
      {
        title: 'Access Level',
        route: 'access-level',
        icon: 'BoxIcon',
      },
    ],
  },
]
